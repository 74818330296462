import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { LiveMessage } from "react-aria-live";

import useDecodedParams from "Hooks/useDecodedParams";

import { capitalize } from "Libs/utils";
import useInput from "Hooks/useInput";
import Button from "UI/Button";

import AlertIcon from "Icons/AlertIcon";
import CheckboxField from "Components/fields/CheckboxField";
import Dropdown from "Components/Dropdown";
import Error from "Components/Error";
import InputField from "Components/fields/InputField";
import InvitationConfirmationModal from "./InvitationConfirmationModal";
import InvitationResendModal from "./InvitationResendModal";
import Label from "Components/fields/Label";
import Loading from "Components/Loading";
import InfoDialog from "Components/InfoDialog";
import EnvironmentList from "../EnvironmentList";

import { ENVIRONMENT_TYPES } from "Constants/constants";

import * as S from "./styles";

const AccessForm = ({
  access,
  enabled,
  environmentTypes,
  environments,
  isLoading,
  create,
  onCancel,
  onDelete,
  onSave,

  errors = {},
  invitation,
  invitationState,
  resendInvitationFct
}) => {
  const intl = useIntl();
  const { environmentId } = useDecodedParams();

  const [accessesList, setAccesses] = useState({});
  const [isChanged, setIsChanged] = useState(false);
  const { inputs, handleInputChange } = useInput(
    {
      superUser: access?.role === "admin"
    },
    () => setIsChanged(true)
  );

  // use only active environments or main environment
  const activeEnviroments = environments.filter(
    env => env.parent === null || env.status !== "inactive"
  );

  const getEnvironments = environmentType =>
    environments
      .filter(e => e.type === environmentType)
      .map(e => e.name)
      .toArray();

  useEffect(() => {
    setAccesses(
      environmentTypes?.entrySeq().reduce((acc, [id, access]) => {
        acc[id] = access || {};
        return acc;
      }, {})
    );
  }, [environmentTypes]);

  const onPermissionChange = (envTypeId, role) => {
    let list = { ...accessesList };
    if (!list[envTypeId]) {
      list[envTypeId] = {};
    }
    list[envTypeId].role = role;

    setAccesses(list);
    setIsChanged(true);
  };

  const handleSave = () => {
    if (access || (inputs.agreement && inputs.email)) {
      let accesses = [];
      for (const envTypeId of Object.keys(accessesList)) {
        if (accessesList[envTypeId].role) {
          accesses.push({
            id: accessesList[envTypeId].id,
            environmentType: envTypeId,
            role: accessesList[envTypeId].role
          });
        }
      }

      onSave({
        accesses,
        email: access?.getUser().email || inputs.email.trim(),
        superUser: inputs.superUser,
        userId: access?.user
      });
      setIsChanged(false);
    }
  };

  const handleResendInvitation = () => {
    resendInvitationFct(inputs.email, {
      accesses: accessesList,
      superUser: inputs.superUser,
      id: access.user
    });
  };

  const getAccessOptions = () => {
    return [
      {
        value: undefined,
        label: intl.formatMessage({ id: "access.options.select_permission" })
      },
      {
        value: "no_access",
        label: intl.formatMessage({ id: "access.options.no_access" })
      },
      {
        value: "admin",
        label: intl.formatMessage({ id: "access.options.admin" })
      },
      {
        value: "viewer",
        label: intl.formatMessage({ id: "access.options.viewer" })
      },
      {
        value: "contributor",
        label: intl.formatMessage({ id: "access.options.contributor" })
      }
    ].filter(option => {
      if (environmentId && option.value === "no_access") {
        return false;
      }
      return true;
    });
  };

  const canSubmit = () => {
    if (access) return true;
    return (
      inputs.email &&
      inputs.agreement &&
      (inputs.superUser || Object.values(accessesList).find(a => a.role))
    );
  };

  const shouldRenderSaveCancelButtons = enabled && (isChanged || !access);
  const shouldRenderDeleteButton =
    enabled && access?.hasPermission && access.hasPermission("#delete");

  const getError = error => {
    if (!error) {
      return false;
    }

    if (Array.isArray(error)) {
      return error[0];
    }

    return error;
  };

  return (
    <S.Wrapper className={access ? "" : "add-form"} create={create}>
      <LiveMessage
        message={intl.formatMessage({
          id: access ? "edit_user" : "add_user"
        })}
        aria-live="polite"
      />
      <S.Form
        id={`${environmentId ? "environment" : "project"}-access-${
          access ? "edit" : "add"
        }-form`}
        aria-labelledby="add-user-heading"
        onSubmit={e => e.preventDefault()}
        create={create}
      >
        {errors?.message && !errors?.detail && <Error>{errors.message}</Error>}
        {!errors?.message && !errors?.detail && errors?.isError && (
          <Error>
            {intl.formatMessage({ id: "invitation.something_went_wrong" })}
          </Error>
        )}

        {!access && (
          <>
            <S.InputWrapper>
              <InputField
                id="email"
                label={intl.formatMessage({ id: "email" })}
                placeholder={intl.formatMessage({ id: "email" })}
                value={inputs.email}
                onChange={handleInputChange}
                error={getError(errors?.detail?.email)}
                disabled={!enabled}
                aria-disabled={!enabled}
                ariaLabel={intl.formatMessage({ id: "user_email" })}
              />
            </S.InputWrapper>
          </>
        )}

        <S.Title>
          {intl.formatMessage({
            id: "access.project.permissions"
          })}
        </S.Title>

        {!environmentId && (
          <S.InputWrapper className="field">
            <CheckboxField
              id="superUser"
              label={`${intl.formatMessage({ id: "project_admin" })}`}
              value={inputs.superUser}
              onChange={handleInputChange}
              error={getError(errors?.detail?.superUser)}
              disabled={!enabled}
              aria-disabled={!enabled}
              forId="superUser"
            />
          </S.InputWrapper>
        )}

        {!inputs.superUser &&
          (!environmentTypes ? (
            <S.LoadingWrapper>
              <Loading loadType="environments" />
            </S.LoadingWrapper>
          ) : (
            <S.EnvironmentPermissions>
              {!!activeEnviroments.size && (
                <>
                  <S.TitleWrapper>
                    <S.Title>
                      {intl.formatMessage({
                        id: "access.environment_types.permissions"
                      })}
                    </S.Title>
                    <InfoDialog
                      text={intl.formatMessage({
                        id: "access.environment_types.description"
                      })}
                      linkText={intl.formatMessage({
                        id: "learnmore"
                      })}
                      to={intl.formatMessage({
                        id: "links.documentation.users"
                      })}
                      align="center"
                    />
                  </S.TitleWrapper>
                  <S.EnvironmentHeaders className="environment-headers">
                    <Label>
                      {intl.formatMessage({
                        id: "access.environment.permissions.env_types"
                      })}
                    </Label>
                    <S.PermissionTypeLabel>
                      {intl.formatMessage({
                        id: "access.environment.permissions.type"
                      })}
                    </S.PermissionTypeLabel>
                    <Label>
                      {intl.formatMessage({
                        id: "environments"
                      })}
                    </Label>
                  </S.EnvironmentHeaders>
                  <hr className="full-width" />
                </>
              )}
              {ENVIRONMENT_TYPES.map(environmentTypeId => {
                const acc = environmentTypes.get(environmentTypeId);

                return (
                  <div key={environmentTypeId}>
                    <S.PermissionWrapper className={"line-permission"}>
                      <S.InputWrapper className="field" key={environmentTypeId}>
                        <Dropdown
                          label={environmentTypeId}
                          options={getAccessOptions()}
                          onChange={({ value }) =>
                            onPermissionChange(environmentTypeId, value)
                          }
                          defaultValue={{
                            value: acc?.role || "",
                            label: acc?.role || "Select permission"
                          }}
                          clearable={false}
                          multi={false}
                          error={getError(errors?.detail?.role)}
                          disabled={!enabled}
                          searchable={false}
                          className="inline"
                          ref={acc?.ref}
                        />
                        <S.EnvironmentsListWrapper>
                          <EnvironmentList
                            environmentsName={getEnvironments(
                              environmentTypeId
                            )}
                          />
                        </S.EnvironmentsListWrapper>
                      </S.InputWrapper>
                      <hr />
                    </S.PermissionWrapper>
                  </div>
                );
              })}
            </S.EnvironmentPermissions>
          ))}

        {!access ? (
          <S.CheckBoxInputWrapper className="field">
            <CheckboxField
              id="agreement"
              label={intl.formatMessage({ id: "add_user_agreement" })}
              value={inputs.agreement}
              onChange={handleInputChange}
              error={getError(errors?.detail?.agreement)}
              forId="agreement"
              required={true}
            />
          </S.CheckBoxInputWrapper>
        ) : (
          enabled &&
          !environmentId && (
            <>
              <div className="warning">
                <AlertIcon /> {intl.formatMessage({ id: "access.alert" })}
              </div>
              <hr />
            </>
          )
        )}
        {isLoading ? (
          <Loading />
        ) : (
          <S.Flex
            create={create}
            shouldRenderSaveCancelButtons={shouldRenderSaveCancelButtons}
          >
            {shouldRenderSaveCancelButtons && (
              <S.ButtonWrapper create={create}>
                <Button
                  variant="primary"
                  id="access-save"
                  type="submit"
                  aria-label={intl.formatMessage({ id: "save" })}
                  onClick={handleSave}
                  disabled={!canSubmit()}
                >
                  {capitalize(intl.formatMessage({ id: "save" }))}
                </Button>
                <Button
                  variant="secondary"
                  id="access-cancel"
                  type="button"
                  aria-label={intl.formatMessage({ id: "cancel" })}
                  onClick={onCancel}
                >
                  {capitalize(intl.formatMessage({ id: "cancel" }))}
                </Button>
              </S.ButtonWrapper>
            )}

            {shouldRenderDeleteButton && (
              <Button
                variant="tertiary"
                style={{ justifySelf: "flex-end" }}
                id="access-delete"
                type="button"
                aria-label={intl.formatMessage({
                  id: "delete"
                })}
                onClick={onDelete}
              >
                {capitalize(intl.formatMessage({ id: "delete" }))}
              </Button>
            )}
          </S.Flex>
        )}
      </S.Form>

      <InvitationConfirmationModal
        isOpen={!!invitation || invitationState === "success"}
        email={invitation?.email}
        closeModal={onCancel}
      />

      <InvitationResendModal
        isOpen={errors?.resendInvitation}
        resendInvitation={handleResendInvitation}
        email={inputs.email}
        closeModal={onCancel}
      />
    </S.Wrapper>
  );
};

AccessForm.propTypes = {
  access: PropTypes.object,
  accesses: PropTypes.object,
  environmentTypes: PropTypes.object,
  accessesAreLoading: PropTypes.bool,
  enabled: PropTypes.bool,
  create: PropTypes.bool,
  environments: PropTypes.object,
  isLoading: PropTypes.bool,
  onCancel: PropTypes.func,
  onDelete: PropTypes.func,
  onSave: PropTypes.func,

  errors: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  invitation: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  invitationState: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  resendInvitationFct: PropTypes.func
};

export default AccessForm;
