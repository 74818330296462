import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import { css, HEADER } from "Libs/themes";

import { Text, Title, Card } from "Containers/announcements/common/styles";

const MinorCard = styled(Card)`
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 8px;
  padding-right: 8px;

  ${Title} {
    color:var(--header-night-color,var(--header-night,var(--night)));
    margin-bottom: 0;
  }
  ${Text} {
    color:var(--header-ebony-color,var(--header-ebony,var(--ebony)));
  }
`;

const Minor = ({ title, content, onClick }) => (
  <MinorCard onClick={onClick}>
    <Title>{title}</Title>
    <Text dangerouslySetInnerHTML={{ __html: content }} />
  </MinorCard>
);

Minor.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  onClick: PropTypes.func
};

export default Minor;
