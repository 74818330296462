import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import { Text, Title, Card } from "../../common/styles";

const MajorCard = styled(Card)`
  background-image: url(${props => props.backgroundImage});
  background-position: bottom right;
  background-repeat: no-repeat;
  background-size: 140px 118px;
  padding-top: 24px;
  padding-right: 24px;
  padding-left: 16px;
  padding-bottom: 50px;
  height: 164px;
  display: flex;
  flex-direction: column;
`;

const MajorText = styled(Text)`
  max-width: 220px;
`;

const Major = ({ artwork, tileTheme, title, content, onClick }) => (
  <MajorCard onClick={onClick} backgroundImage={artwork} tileTheme={tileTheme}>
    <Title>{title}</Title>
    <MajorText dangerouslySetInnerHTML={{ __html: content }} />
  </MajorCard>
);

Major.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  artwork: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  tileTheme: PropTypes.string
};

export default Major;
