import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import ReactModal from "react-modal";
import { useIntl } from "react-intl";

import CloseIcon from "Icons/CloseIcon";
import { getCSSVarString, ICON, css, BUTTON } from "Libs/themes";

const CloseModal = styled.button.attrs({
  type: "button"
})`
  position: absolute;
  top: 24px;
  right: 24px;

  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  border-radius: 2px;
  padding: 10px;
  box-sizing: border-box;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  transition: background-color 0.1s ease-in;

  svg {
    height: auto;
    width: 100%;
    g path {
      fill: var(--icon-granite-fill,var(--icon-granite,var(--granite))) !important;
      transition: fill 0.1s ease-in;
    }
  }

  &:hover {
    background-color:var(--button-granite-background-color,var(--button-granite,var(--granite)));
    svg g path {
      fill: var(--icon-granite-fill,var(--icon-granite,var(--granite))) !important;
    }
  }
`;

const Modal = ({ isOpen, label, close, children }) => {
  const { formatMessage } = useIntl();
  return (
    <ReactModal
      isOpen={isOpen}
      overlayClassName={{
        base: "overlay overlay-announcements",
        afterOpen: "overlay-after-open",
        beforeClose: "overlay-before-close"
      }}
      role="dialog"
      aria={{
        labelledby: label,
        describedby: "modal-body",
        modal: "true"
      }}
      ariaHideApp={false}
      onRequestClose={close}
      className="modal modal-announcements"
      bodyOpenClassName="ReactModal__Body--open overflow-hidden"
    >
      {children}
      {close && (
        <CloseModal
          aria-label={formatMessage({ id: "close" })}
          onClick={() => close()}
        >
          <CloseIcon />
        </CloseModal>
      )}
    </ReactModal>
  );
};

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func,
  children: PropTypes.node,
  onAfterClose: PropTypes.func,
  label: PropTypes.string
};

export default Modal;
