import React, { useState, useEffect, useRef } from "react";
import styled, { css as styledCSS } from "styled-components";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { List } from "immutable";
import CustomScroll from "react-custom-scroll";
import useMediaQuery from "Hooks/useMediaQuery";
import { connect } from "react-redux";
import { display } from "Reducers/announcements";

import { css, SECTION, BUTTON, getCSSVarString } from "Libs/themes";

import Minor from "./Minor";
import Major from "./Major";

import whatsNewImage from "Images/whats-new.gif";

const Layout = styled.div`
  position: relative;
  margin-right: 16px;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
`;

const DropdownBody = styled.div`
  background-color:var(--section-snow-background-color,var(--section-snow,var(--snow)));
  box-shadow: var(--section-box-shadow-box-shadow,var(--section-box-shadow,var(--box-shadow)))
    var(--section-granite-box-shadow-color,var(--section-granite,var(--granite)));
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  transition: height 0.2s ease-in;

  z-index: 100;
  position: fixed;
  top: 55px;
  right: 0;
  left: 0;
  margin-left: 8px;
  margin-right: 8px;
  margin-top: 8px;
  min-height: 0;
  height: 500px;

  @media screen and (min-width: 500px) {
    position: absolute;
    top: 100%;
    margin-left: 0;
    margin-right: 0;
    min-width: 400px;
    left: inherit;
    max-height: calc(100vh - 70px);
  }

  ${props =>
    !props.isCollapsed &&
    styledCSS`
      height: 770px;
      @media screen and (max-height: 820px) {
        max-height: calc(100vh - 70px);
      }
    `};
`;

const AnnouncementList = styled.div`
  color:var(--section-night-color,var(--section-night,var(--night)));
  overflow: hidden;
  position: relative;
  flex: 1;

  .rcs-custom-scroll .rcs-custom-scrollbar {
    width: 4px;
  }

  .rcs-custom-scroll .rcs-inner-container {
    padding-right: 16px;
  }

  .rcs-custom-scroll .rcs-inner-handle {
    background-color:var(--section-granite-background-color,var(--section-granite,var(--granite)));
    border-radius: 2px;
  }
`;

const DropdownHeader = styled.div`
  font-weight: bold;
  font-size: 15px;
  line-height: 24px;
  color:var(--section-night-color,var(--section-night,var(--night)));
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 24px;
  border-bottom: 1px solid
    var(--section-periwinkle-grey-border-color,var(--section-periwinkle-grey,var(--periwinkle-grey)));
`;

const MoreButton = styled.button.attrs({
  type: "button"
})`
  background-color: transparent;
  text-decoration: none;
  color:var(--button-skye-color,var(--button-skye,var(--skye)));
  border: 0;
  width: 100%;
  padding-top: 14px;
  padding-bottom: 14px;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  cursor: pointer;
`;

const DropdownToggle = styled.button.attrs({
  type: "button",
  "aria-haspopup": true,
  "aria-expanded": props => props.isOpen
})`
  background-color: transparent;
  border: 1px solid transparent;
  color: ${props => (props.theme.name === "contrast" ? "#ffffff" : "#ffbdbb")};
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  height: 100%;
  cursor: pointer;
  padding: 6px 9px;
  border-radius: 2px;
  transition: 0.2s color;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  white-space: nowrap;

  &:hover {
    color:var(--button-snow-color,var(--button-snow,var(--snow)));
    background-color: ${props =>
      props.theme.name === "contrast"
        ? "#98A0AB"
        : "rgba(255, 255, 255, 0.12)"};
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 1px #4786ff;
  }

  position: relative;

  ${props =>
    props.isOpen &&
    css`
      color:var(--button-snow-color,var(--button-snow,var(--snow)));
      &:after {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid
          var(--button-snow-border-color,var(--button-snow,var(--snow)));
        border-radius: 2px;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        z-index: 2;
        bottom: -14px;

        @media screen and (min-width: 500px) {
          right: 16px;
          bottom: -9px;
          left: auto;
        }
      }
    `};
`;

const ButtonContainer = styled.div`
  position: relative;
  cursor: pointer;
`;

const WhatsNewImage = styled.img.attrs({
  src: whatsNewImage,
  alt: "",
  role: "presentation"
})`
  width: 24px;
  height: 24px;
  margin: -1px 8px 0 0;
  vertical-align: middle;
`;

const Dropdown = ({ announcements, className, displayModal, error }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const dropdownBodyRef = useRef(null);
  const match = useMediaQuery("(min-width: 764px)");
  const close = () => {
    setIsCollapsed(true);
    setIsOpen(false);
    document.body.style.overflow = null;
  };

  const open = () => {
    document.body.style.overflow = "hidden";
    setIsOpen(true);
  };

  const toggle = status => (status ? open() : close());

  const outOfBoundsClick = event =>
    !dropdownBodyRef?.current?.contains(event.target) && close();

  useEffect(() => {
    document.addEventListener("click", outOfBoundsClick);
    return () => document.removeEventListener("click", outOfBoundsClick);
  }, [outOfBoundsClick]);

  if (error) return false;

  return (
    <Layout className={className}>
      <ButtonContainer id="whats-new" onClick={() => toggle(!isOpen)}>
        {match && (
          <>
            {announcements.some(({ read }) => !read) && <WhatsNewImage />}
            <DropdownToggle isOpen={isOpen}>
              <FormattedMessage id="announcements.whatsnew" />
            </DropdownToggle>
          </>
        )}
      </ButtonContainer>

      {isOpen && (
        <DropdownBody
          ref={dropdownBodyRef}
          dropdisOpen={isOpen}
          isCollapsed={isCollapsed}
        >
          <DropdownHeader>
            <FormattedMessage id="announcements.whatsnew" />
          </DropdownHeader>

          <AnnouncementList>
            <CustomScroll heightRelativeToParent="100%" addScrolledClass={true}>
              {announcements.map(announcement => {
                const Kind = announcement.artwork ? Major : Minor;
                return (
                  <Kind
                    key={announcement.id}
                    onClick={
                      announcement.steps.length
                        ? () => {
                            close();
                            displayModal(announcement.id);
                          }
                        : undefined
                    }
                    {...announcement}
                  />
                );
              })}
            </CustomScroll>
          </AnnouncementList>
          {announcements.size > 3 && (
            <MoreButton
              onClick={() => {
                setIsCollapsed(!isCollapsed);
                document.activeElement.blur();
              }}
            >
              <FormattedMessage
                id={isCollapsed ? "announcements.more" : "announcements.less"}
              />
            </MoreButton>
          )}
        </DropdownBody>
      )}
    </Layout>
  );
};

Dropdown.propTypes = {
  className: PropTypes.string,
  announcements: PropTypes.instanceOf(List),
  displayModal: PropTypes.func,
  error: PropTypes.object
};

const mapStateToProps = ({ announcements }) => ({
  announcements: announcements.get("announcements"),
  error: announcements.get("error")
});

const mapDispatchToProps = dispatch => ({
  displayModal: id => dispatch(display(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(Dropdown);
