import React, { useState } from "react";
import PropTypes from "prop-types";
import styled, { css as styledCSS } from "styled-components";
import { FormattedMessage } from "react-intl";
import CustomScroll from "react-custom-scroll";

import { css, HEADER, SECTION, BUTTON, getCSSVarString } from "Libs/themes";

import Button from "Components/Button";
import { _fadeIn, cardThemes, _anchor } from "../common/styles";

const Layout = styled.div`
  background-color:var(--section-snow-background-color,var(--section-snow,var(--snow)));
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: calc(100vh - (68px * 2));
  box-shadow: ${props =>
    props.theme.name === "dark"
      ? "0 4px 8px rgba(37,36,40,0.30)"
      : "0px 6px 24px rgba(152, 160, 171, 0.4)"};
`;

const Content = styled.div`
  height: 100%;
  overflow: hidden;
`;

const ArtworkContainer = styled.div`
  background-image: url(${props => props.backgroundImage});
  background-position: center;
  background-repeat: no-repeat;
  ${props => props.tileTheme};
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
`;

const Artwork = styled.img`
  max-height: 200px;
  max-width: 100%;
`;

const Text = styled.div`
  padding-top: ${({ hasArtwork }) => (hasArtwork ? "16px" : "32px")};
  padding-bottom: 8px;
  padding-right: 32px;
  padding-left: 32px;

  font-weight: normal;
  font-size: 15px;
  line-height: 24px;
  color: ${props => (props.theme.name === "dark" ? "#fff" : "#4a495e")};

  h3 {
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    color:var(--header-night-color,var(--header-night,var(--night)));
    margin-bottom: 24px;
    margin-top: 16px;
  }

  h4 {
    font-weight: bold;
    font-size: 15px;
    line-height: 24px;
    color: ${props => (props.theme.name === "dark" ? "#fff" : "#4a495e")};
    margin: 0;
  }

  h4 + p {
    margin-top: 8px;
  }

  a {
    ${_anchor};
  }

  p:last-child {
    font-size: 14px;
  }
`;

const Slide = styled.div`
  ${_fadeIn};

  .rcs-custom-scroll .rcs-custom-scrollbar {
    width: 4px;
  }

  .rcs-custom-scroll .rcs-inner-handle {
    background-color:var(--section-granite-background-color,var(--section-granite,var(--granite)));
    border-radius: 2px;
  }

  .rcs-positioning + .rcs-inner-container:after {
    position: absolute;
    background-color:var(--section-skye-light-background-color,var(--section-skye-light,var(--skye-light)));
    height: 1px !important;
    top: auto;
    bottom: 0;
    right: 0;
  }

  height: 100%;
  min-height: 0;
  min-width: 0;
  position: relative;
`;

const _knobActive = styledCSS`
  background-color:var(--button-skye-background-color,var(--button-skye,var(--skye)));
`;

const Knob = styled.button.attrs({
  type: "button"
})`
  display: inline-block;
  padding: 0;
  width: 8px;
  height: 8px;
  border-radius: 2px;
  transform: rotate(-45deg);
  margin-left: 9px;
  border: 1px solid transparent;
  background-color:var(--button-periwinkle-grey-background-color,var(--button-periwinkle-grey,var(--periwinkle-grey)));
  transition-property: background-color, border-color;
  transition-duration: 0.1s;
  cursor: pointer;
  outline: none;

  ${props =>
    props.isActive &&
    styledCSS`
      cursor: default;
      pointer-events: none;
      ${_knobActive};
    `};

  &:hover {
    ${_knobActive};
  }

  &:focus {
    box-shadow: 0px 0px 2px
      var(--button-skye-dark-box-shadow-color,var(--button-skye-dark,var(--skye-dark)));
    border-color:var(--button-skye-dark-border-color,var(--button-skye-dark,var(--skye-dark)));
  }
`;

const Knobs = styled.div``;

const Controls = styled.div`
  display: flex;
  padding-right: 32px;
  padding-bottom: 24px;
  padding-left: 32px;
  padding-top: 24px;
  margin-top: auto;
  justify-content: space-between;
`;

const NextButton = styled(Button)`
  margin: 0 0 0 auto !important;
  border: 1px solid transparent;
  &:active {
    border: 1px solid var(--button-skye-dark-border-color,var(--button-skye-dark,var(--skye-dark)));
  }
`;

const ModalContent = ({ className, slides, requestClose, title }) => {
  const [step, setStep] = useState(0);
  const isLastSlide = step === slides.length - 1;

  return (
    <Layout className={className}>
      <Content>
        {slides.map(
          ({ artwork, content, tileTheme }, index) =>
            step === index && (
              <Slide key={index}>
                <CustomScroll
                  heightRelativeToParent="100%"
                  addScrolledClass={true}
                >
                  {artwork && (
                    <ArtworkContainer tileTheme={cardThemes[tileTheme]}>
                      <Artwork src={artwork} alt={title} />
                    </ArtworkContainer>
                  )}
                  <Text
                    hasArtwork={!!artwork}
                    dangerouslySetInnerHTML={{ __html: content }}
                  />
                </CustomScroll>
              </Slide>
            )
        )}
      </Content>
      <Controls>
        {slides.length > 1 && (
          <Knobs>
            {slides.map((slide, index) => (
              <Knob
                key={index}
                isActive={index === step}
                onClick={() => setStep(index)}
              />
            ))}
          </Knobs>
        )}
        <NextButton
          onClick={() => (isLastSlide ? requestClose() : setStep(step + 1))}
        >
          {isLastSlide ? (
            <FormattedMessage id="announcements.gotit" />
          ) : (
            <FormattedMessage id="next" />
          )}
        </NextButton>
      </Controls>
    </Layout>
  );
};

ModalContent.propTypes = {
  className: PropTypes.string,
  slides: PropTypes.array,
  requestClose: PropTypes.func,
  title: PropTypes.string
};

export default ModalContent;
