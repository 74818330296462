import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import styled from "styled-components";
import moment from "moment";

import { css, SECTION, BUTTON } from "Libs/themes";

import {
  loadMoreProjectActivities,
  loadProjectActivities,
  loadEnvironmentActivities,
  loadMoreEnvironmentActivities,
  loadIntegrationActivities,
  loadMoreIntegrationActivities,
  getSelectors,
  getActivityFilters,
  setActivitiesTab,
  getActivityTab,
  ActivityTabName
} from "Reducers/activity";
import useDecodedParams from "Hooks/useDecodedParams";

import ActivityList from "../../components/ActivityList";
import LogModal from "../../components/ActivityList/LogModal";
import Filter from "../../components/ActivityList/Filter";
import SectionHeader from "./SectionHeader";
import ActivitySectionSkeleton from "./ActivitySectionSkeleton";
import activityTypes from "Constants/activityTypes";

const filterOptions = Object.keys(activityTypes).map(key => ({
  label: activityTypes[key].label,
  value: activityTypes[key].label
}));
filterOptions.unshift({ label: "show all", value: "all_type" });

const allFilterNames = filterOptions.map(o => o.value);

const toggleActiveFilterNames = (appliedFilters = [], filterName = null) => {
  if (appliedFilters.includes(filterName))
    return appliedFilters.filter(name => name !== filterName);
  filterName && appliedFilters.push(filterName);
  return appliedFilters;
};

const ellapsedTimeIsLessThan = (date, durationInSeconds) => {
  const duration = moment.duration(moment().diff(moment(date)));
  return durationInSeconds > duration.asSeconds();
};

/**
 * Filter activities not older than ellapsedTime and limit by limitActivityCount
 * @param {Activities} activities Activities to filter
 * @param {number} ellapsedSeconds Time in seconds by which this activity must not be older than
 * @param {number} limitActivityCount Total activities to be considered in created date ASC
 * @returns Activities filtered by ellapsed time and limit
 */
const filterRecentActivities = (
  activities,
  ellapsedSeconds = Infinity,
  limitActivityCount = Infinity
) => {
  const limitCount =
    activities.size < limitActivityCount ? activities.size : limitActivityCount;
  return activities
    .sort(
      (item1, item2) =>
        new Date(item2.created_at).getTime() -
        new Date(item1.created_at).getTime()
    )
    .slice(0, limitCount)
    .filter(({ created_at }) =>
      ellapsedTimeIsLessThan(created_at, ellapsedSeconds)
    );
};

const ActivitySection = ({ context }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();

  const { appliedFilters, tabName } = useSelector(state => {
    const filter = getActivityFilters(state, context);
    return {
      appliedFilters: filter ? filter : allFilterNames,
      tabName: getActivityTab(state)
    };
  });

  const {
    projectId,
    organizationId,
    environmentId,
    integrationId,
    activityId
  } = useDecodedParams();
  const {
    all,
    completed,
    inProgress,
    pending,
    hasMore,
    isLoading,
    isLoadingMore
  } = getSelectors(context, {
    projectId,
    organizationId,
    environmentId,
    integrationId
  });

  const allActivities = useSelector(all);

  const pendingActivity = useSelector(pending);
  const recentPendingActivities = useMemo(
    () => filterRecentActivities(pendingActivity),
    [pendingActivity]
  );

  const completeActivities = useSelector(completed);
  const recentCompletedActivities = useMemo(
    () => filterRecentActivities(completeActivities),
    [completeActivities]
  );

  const inProgressActivity = useSelector(inProgress);
  const recentInprogressActivities = useMemo(
    () => filterRecentActivities(inProgressActivity),
    [inProgressActivity]
  );

  const project = useSelector(state =>
    state.project.getIn(["data", organizationId, projectId])
  );
  const hasMoreActivities = useSelector(hasMore);
  const isLoadingActivities = useSelector(isLoading);
  const isLoadingMoreActivities = useSelector(isLoadingMore);

  // We show just one section if the all tab is selected but also
  // if activities are empty
  const shouldShowFilter = tabName === ActivityTabName.All;

  const shouldShowRecentSection = tabName === ActivityTabName.Recent;

  const shouldShowMoreButton =
    tabName !== ActivityTabName.Recent &&
    !(isLoadingActivities || isLoadingMoreActivities) &&
    hasMoreActivities;

  const handleFilterChange = e => {
    const { name } = e.target;
    loadActivity(name);
  };

  const getFilterNames = toggleName => {
    if (tabName === ActivityTabName.All && toggleName == "all_type") {
      return appliedFilters.includes("all_type") ? [] : allFilterNames;
    }

    if (tabName === ActivityTabName.Recent) {
      const filterNames = toggleActiveFilterNames(
        appliedFilters,
        toggleName
      ).filter(item => item !== "cron");
      return filterNames;
    }

    const filterNames = toggleActiveFilterNames(appliedFilters, toggleName);
    const filterWithoutAll = filterNames.filter(n => n !== "all_type");
    return filterWithoutAll.length === allFilterNames.length - 1
      ? allFilterNames
      : filterNames.filter(name => name !== "all_type");
  };

  const onTabChange = tabName => {
    dispatch(setActivitiesTab(tabName));
  };

  const loadActivity = filterName => {
    const filter = getFilterNames(filterName);
    switch (true) {
      case context === "environment":
        dispatch(
          loadEnvironmentActivities(
            projectId,
            environmentId,
            organizationId,
            filter,
            context
          )
        );
        break;
      case context.startsWith("integration"):
        if (!integrationId) break;
        dispatch(
          loadIntegrationActivities(
            organizationId,
            projectId,
            integrationId,
            [],
            context
          )
        );
        break;
      default:
        dispatch(
          loadProjectActivities(projectId, organizationId, filter, context)
        );
    }
  };

  // loadMoreActivity dispatches a different action depending if we are
  // in the environment or project context, this will make the action
  // fetch the activities from the corresponding endpont
  const loadMoreActivity = () => {
    const filter = getFilterNames();
    switch (true) {
      case context === "environment":
        dispatch(
          loadMoreEnvironmentActivities(
            projectId,
            environmentId,
            organizationId,
            filter,
            context
          )
        );
        break;
      case context.startsWith("integration"):
        dispatch(
          loadMoreIntegrationActivities(
            organizationId,
            projectId,
            integrationId,
            filter,
            context
          )
        );
        break;
      default:
        dispatch(
          loadMoreProjectActivities(projectId, organizationId, filter, context)
        );
    }
  };

  useEffect(() => {
    loadActivity();
  }, [environmentId, projectId, integrationId, tabName]);

  const getOriginUrl = () => {
    let url = `/${organizationId}/${projectId}`;
    if (environmentId) {
      return `${url}/${encodeURIComponent(environmentId)}`;
    }
    if (integrationId) {
      return `${url}/-/integrations/${integrationId}`;
    }
    return url;
  };

  const closeLog = () => {
    history.push(getOriginUrl());
  };

  return (
    <Container aria-labelledby="project-activity-heading">
      <SectionHeader showing={tabName} onTabChange={onTabChange}>
        {shouldShowFilter && (
          <Filter
            options={filterOptions}
            activeOptions={appliedFilters}
            onChange={e => handleFilterChange(e)}
          />
        )}
      </SectionHeader>
      {shouldShowRecentSection ? (
        <>
          <SubsectionHeadingFirst>
            {intl.formatMessage({ id: "activities.headers.running" })}
            <ActivityCount> ({recentInprogressActivities.size})</ActivityCount>
          </SubsectionHeadingFirst>
          {isLoadingActivities ? (
            <ActivitySectionSkeleton />
          ) : (
            <BoxLayout>
              <ActivityList
                organizationId={organizationId}
                projectId={projectId}
                loadMore={loadMoreActivity}
                loadActivitiesOfType={loadActivity}
                activities={recentInprogressActivities}
                activityType="running"
                activityIsLoading={isLoadingMoreActivities}
                hasMore={hasMoreActivities}
                canEditProject={
                  project &&
                  typeof project.hasPermission === "function" &&
                  project.hasPermission("#edit")
                }
                activityContext={context}
              />
            </BoxLayout>
          )}
          <SubsectionHeading>
            {intl.formatMessage({ id: "activities.headers.pending" })}
            {!(isLoadingActivities || isLoadingMoreActivities) && (
              <ActivityCount> ({recentPendingActivities.size})</ActivityCount>
            )}
          </SubsectionHeading>
          {isLoadingActivities ? (
            <>
              <ActivitySectionSkeleton />
              <ActivitySectionSkeleton />
            </>
          ) : (
            <BoxLayout>
              <ActivityList
                organizationId={organizationId}
                projectId={projectId}
                loadMore={loadMoreActivity}
                loadActivitiesOfType={loadActivity}
                activities={recentPendingActivities}
                activityType="pending"
                activityIsLoading={isLoadingMoreActivities}
                hasMore={hasMoreActivities}
                canEditProject={
                  project &&
                  typeof project.hasPermission === "function" &&
                  project.hasPermission("#edit")
                }
                activityContext={context}
              />
            </BoxLayout>
          )}
          <SubsectionHeading>
            {intl.formatMessage({ id: "activities.headers.recent" })}
            {!(isLoadingActivities || isLoadingMoreActivities) && (
              <ActivityCount> ({recentCompletedActivities.size})</ActivityCount>
            )}
          </SubsectionHeading>
          {isLoadingActivities ? (
            <>
              <ActivitySectionSkeleton />
              <ActivitySectionSkeleton />
            </>
          ) : (
            <BoxLayout>
              <ActivityList
                organizationId={organizationId}
                projectId={projectId}
                loadMore={loadMoreActivity}
                loadActivitiesOfType={loadActivity}
                activities={recentCompletedActivities}
                activityIsLoading={isLoadingMoreActivities}
                hasMore={hasMoreActivities}
                canEditProject={
                  project &&
                  typeof project.hasPermission === "function" &&
                  project.hasPermission("#edit")
                }
                activityContext={context}
              />
            </BoxLayout>
          )}
        </>
      ) : isLoadingActivities ? (
        <>
          <ActivitySectionSkeleton />
          <ActivitySectionSkeleton />
          <ActivitySectionSkeleton />
          <ActivitySectionSkeleton />
        </>
      ) : (
        // All activities
        <BoxLayout>
          <ActivityList
            organizationId={organizationId}
            projectId={projectId}
            loadMore={loadMoreActivity}
            loadActivitiesOfType={loadActivity}
            activities={allActivities}
            activityIsLoading={isLoadingMoreActivities}
            hasMore={hasMoreActivities}
            canEditProject={
              project &&
              typeof project.hasPermission === "function" &&
              project.hasPermission("#edit")
            }
            activityContext={context}
          />
        </BoxLayout>
      )}
      {shouldShowMoreButton && (
        <SectionFooter>
          <LinkButton onClick={loadMoreActivity}>Show more</LinkButton>
        </SectionFooter>
      )}

      {activityId && <LogModal closeModal={closeLog} />}
    </Container>
  );
};

ActivitySection.propTypes = {
  context: PropTypes.string
};

const Container = styled.section`
  width: 100%;
  min-width: 65%;
  box-sizing: border-box;
  padding: 8px 0 !important;
`;

const BoxLayout = styled.div`
  padding: 0 32px;
`;

const SubsectionHeading = styled.h3`
  font-size: 15px;
  font-weight: 600;
  color:var(--section-ebony-light-color,var(--section-ebony-light,var(--ebony-light)));
`;

const SubsectionHeadingFirst = styled(SubsectionHeading)`
  margin-top: -10px;
`;

const ActivityCount = styled.span`
  font-size: 13px;
  color:var(--section-granite-color,var(--section-granite,var(--granite)));
`;

const SectionFooter = styled.footer`
  padding: 10px;
  text-align: center;
`;

const LinkButton = styled.button`
  background: transparent;
  cursor: pointer;
  padding: 6px 8px;
  height: auto;
  border: none;
  color:var(--button-skye-color,var(--button-skye,var(--skye)));
  font-size: 14px;

  &:hover,
  &:focus {
    background: transparent;
    text-decoration: underline;
  }
`;

export default ActivitySection;
