import React from "react";
import PropTypes from "prop-types";
import { List } from "immutable";

import { connect } from "react-redux";
import { markAsRead, hide } from "Reducers/announcements";

import AnnouncementModal from "./Modal";
import ModalContent from "./Content";

const AnnouncementModalContainer = ({
  announcements,
  markAsRead,
  hide,
  announcementsError
}) => {
  const close = announcement => {
    hide(announcement.id);
    !announcement.read && markAsRead(announcement.id);
  };

  return (
    !announcementsError &&
    announcements
      .filter(({ active }) => active)
      .slice(0, 1)
      .map(announcement => (
        <AnnouncementModal
          isOpen={announcement.active}
          key={announcement.id}
          label={announcement.title}
          close={() => close(announcement)}
        >
          <ModalContent
            slides={announcement.steps}
            title={announcement.title}
            requestClose={() => close(announcement)}
          />
        </AnnouncementModal>
      ))
  );
};

AnnouncementModalContainer.propTypes = {
  announcements: PropTypes.instanceOf(List),
  markAsRead: PropTypes.func,
  hide: PropTypes.func,
  announcementsError: PropTypes.object
};

const mapStateToProps = ({ announcements }) => ({
  announcements: announcements.get("announcements"),
  announcementsError: announcements.get("error")
});

const mapDispatchToProps = dispatch => ({
  markAsRead: id => dispatch(markAsRead(id)),
  hide: id => dispatch(hide(id))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AnnouncementModalContainer);
