import styled, { css as styledCSS, keyframes } from "styled-components";
import { css, LINK, SECTION, getCSSVarString, BUTTON } from "Libs/themes";

const fadeIn = keyframes`
  0% {
    opacity: 0.01;
  }
  100% {
    opacity: 1;
  }
`;

export const _anchor = css`
  text-decoration: none;
  color:var(--link-skye-color,var(--link-skye,var(--skye)));
  position: relative;
  padding: 0;
  font-size: inherit;
  line-height: inherit;

  &:focus {
    outline: none;
  }

  &:focus:after,
  &:hover:after {
    content: "";
    width: 100%;
    position: absolute;
    height: 1px;
    background-color:var(--link-skye-light-background-color,var(--link-skye-light,var(--skye-light)));
    bottom: -1px;
    left: 0;
    display: inline-block;
  }
`;

export const _fadeIn = styledCSS`
  animation: ${fadeIn} 0.1s ease-in;
`;

export const Title = styled.div`
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  color: #1a192b;
  margin-bottom: 8px;
  transition: color 0.1s ease-in;
`;

export const Text = styled.p`
  font-size: 13px;
  line-height: 20px;
  color: #302f45;
  margin: 0;

  a {
    ${_anchor};
  }
`;

export const cardThemes = {
  tusk: styledCSS`
    background-color: #f0f4c3;
  `,
  "light-pink": styledCSS`
    background-color: #ffeeee;
  `,
  "skye-light": styledCSS`
    background-color: #e7f0ff;
  `,
  ice: styledCSS`
    background-color: #f5f7fa;
  `,
  ebony: styledCSS`
    background-color: #302f45;
    ${Text}, ${Title} {
      color: #ffffff;
    }
  `
};

export const Card = styled.button.attrs({
  tabindex: props => (props.onClick ? "0" : "-1"),
  type: "button"
})`
  border-radius: 2px;
  border: 0;
  background-color: transparent;
  text-align: left;
  cursor: ${props => (props.onClick ? "pointer" : "default")};
  width: 100%;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  margin-top: 8px;
  margin-bottom: 8px;
  margin-left: 8px;
  box-shadow: none;
  transition: 0.25s box-shadow ease-in-out;

  ${props => props.tileTheme && cardThemes[props.tileTheme]};

  &:focus {
    ${props =>
      props.onClick &&
      styledCSS`
        box-sizing: border-box;
        box-shadow: 0px 0px 2px ${getCSSVarString(
          BUTTON,
          "skye-dark",
          "box-shadow-color"
        )};
        border-radius: 4px;
      `};
    outline: none;
  }

  &:hover {
    ${props =>
      props.onClick &&
      styledCSS`
        box-shadow: ${getCSSVarString(
          BUTTON,
          "box-shadow",
          "box-shadow"
        )} var(--button-granite-box-shadow-color,var(--button-granite,var(--granite)));
      `};
  }

  &:active ${Title}, &:hover ${Title} {
    ${props =>
      props.onClick &&
      styledCSS`
        color:var(--section-skye-color,var(--section-skye,var(--skye)));
      `};
  }
`;
